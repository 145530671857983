import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Caption = makeShortcode("Caption");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`These are guidelines to use as a reference when designing new octicons.`}</p>
    <h2>{`Grid sizes`}</h2>
    <p>{`Always design two versions of each icon: a 16px version and a 24px version.`}</p>
    <Box display={'grid'} gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <div>
    <Box display={'flex'} bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/10576fdd-d2d4-4d51-ba73-9c1a698db8e9" />
    </Box>
    <Caption mdxType="Caption">16x16 grid</Caption>
  </div>
  <div>
    <Box display={'flex'} bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/0f2b4620-cbf8-4125-88e2-888c615fefaa" />
    </Box>
    <Caption mdxType="Caption">24x24 grid</Caption>
  </div>
    </Box>
    <p><strong parentName="p">{`12px`}</strong>{` icons are special and should only be created if there is a specific use case where a 16px icon can't be used.`}</p>
    <h2>{`Stroke`}</h2>
    <p>{`Use a consistent stroke width of 1.5px for both 16px and 24px icons.`}</p>
    <div>
  <Box display={'flex'} bg="gray.1" mdxType="Box">
    <img width="400" alt="" src="https://github.com/primer/design/assets/912236/9d95d002-4414-450f-bbb9-06dfdd5e4318" />
  </Box>
  <Caption mdxType="Caption">1.5px stroke on 16px icon</Caption>
    </div>
    <h2>{`Reference shapes`}</h2>
    <p>{`Use the following shapes as sizing references to ensure that the "optical volume" of your icon is consistent with the rest of the set. See the "Grid and Optical Volume" section of `}<a parentName="p" {...{
        "href": "https://blog.nucleoapp.com/nucleo-icon-guidelines-introduction-70092f8b4697"
      }}>{`Nucleo's Icon Guidelines`}</a>{` to learn more about "optical volume."`}</p>
    <h3>{`Circle`}</h3>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/7325a318-9e77-40c6-93a6-9dc5dd1eb4db" />
    </Box>
    <Caption mdxType="Caption">Circle on 16x16 grid</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/66f823d9-60a1-49cd-8836-551f71742c03" />
    </Box>
    <Caption mdxType="Caption">Circle on 16x16 grid example</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/3e834ef5-74df-425b-b65a-65d54037b11e" />
    </Box>
    <Caption mdxType="Caption">Circle on 24x24 grid</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/af9d20fb-9f72-4714-9485-790f8071436d" />
    </Box>
    <Caption mdxType="Caption">Circle on 24x24 grid example</Caption>
  </div>
    </Box>
    <h3>{`Square`}</h3>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/7600dc20-ab77-4f75-a640-99f1cf372073" />
    </Box>
    <Caption mdxType="Caption">Square on 16x16 grid example</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/e594f7da-5a1e-4761-8919-aee401bb1a6b" />
    </Box>
    <Caption mdxType="Caption">Square on 16x16 grid example</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/2b1a9d33-d341-44db-af11-eff3e9327d5a" />
    </Box>
    <Caption mdxType="Caption">Square on 24x24 grid</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/821e74d2-26f5-4dc4-baab-f7eadf7ab29d" />
    </Box>
    <Caption mdxType="Caption">Square on 24x24 grid example</Caption>
  </div>
    </Box>
    <h3>{`Rectangle`}</h3>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/3878c01a-62d9-4b54-8e10-55ac069f180a" />
    </Box>
    <Caption mdxType="Caption">Rectangle on 16x16 grid</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/6984f43b-0b3a-4750-9ff9-f1c30329b599" />
    </Box>
    <Caption mdxType="Caption">Rectangle on 16x16 grid example</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/40915a1e-5763-40ee-bc1d-cd0dfc1d1032" />
    </Box>
    <Caption mdxType="Caption">Rectangle on 24x24 grid</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/b120ab07-99f1-40ec-bb08-36205a4a19a9" />
    </Box>
    <Caption mdxType="Caption">Rectangle on 24x24 grid example</Caption>
  </div>
    </Box>
    <h2>{`Caps and joins`}</h2>
    <p>{`Use round caps and joins.`}</p>
    <div>
  <Box display="flex" bg="gray.1" mdxType="Box">
    <img width="400" alt="" src="https://github.com/primer/design/assets/912236/490d4490-28f3-471c-af8f-e6e2081230e5" />
  </Box>
  <Caption mdxType="Caption">Round caps and joins</Caption>
    </div>
    <h2>{`Corners`}</h2>
    <p>{`Use 1px radius for corners unless a different radius makes the icon more recognizable (e.g. `}<a parentName="p" {...{
        "href": "./repo-16"
      }}>{`repo`}</a>{`).`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mb={4} mdxType="Box">
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/5ac5238b-d1b9-4586-ba7c-b442353040a3" />
    </Box>
    <Caption mdxType="Caption">1px radius on 16px icon</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/5aa7f005-a1f7-4307-998b-8cf3a82ed4ca" />
    </Box>
    <Caption mdxType="Caption">1px radius on 24px icon</Caption>
  </div>
    </Box>
    <p>{`Use 0.25px radius for small filled elements inside icons, like filled arrowheads.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/cb25752e-128d-49a8-aa8c-0b5b5ccfaaa0" />
    </Box>
    <Caption mdxType="Caption">0.25px radius on arrowhead</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/573dae9e-2d89-4a68-b4ee-88b4d3de759f" />
    </Box>
    <Caption mdxType="Caption">0.25px radius on bookmark in repo icon</Caption>
  </div>
    </Box>
    <h2>{`Gaps`}</h2>
    <p>{`Use a 1px gap to separate overlapping objects.`}</p>
    <Box display="flex" mb={4} flexDirection="column" mdxType="Box">
  <Box display="flex" bg="gray.1" mdxType="Box">
    <img width="400" alt="" src="https://github.com/primer/design/assets/912236/2c4895c5-3df4-4248-b52f-f6548dee41cc" />
  </Box>
  <Caption mdxType="Caption">1px gap in comment-discussion icon</Caption>
    </Box>
    <p>{`Use a 1.5px gap around modifier elements, like lines and arrows.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/ba91cef8-1bff-49e4-9e76-6f8967259be1" />
    </Box>
    <Caption mdxType="Caption">1.5px gap in bookmark-slash icon</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/20483001-279f-4e04-af61-8232bb34d6c1" />
    </Box>
    <Caption mdxType="Caption">1.5px gap in package-dependencies icon</Caption>
  </div>
    </Box>
    <h2>{`Perspective`}</h2>
    <p>{`Use 2D perspective unless depth makes the icon more recognizable.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <Do mdxType="Do">
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/42cd0a54-d378-491a-b5cd-a7a561dc457c" />
    </Box>
    <Caption mdxType="Caption">Use 2D perspective</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/f07d14c8-acf6-4d68-955a-bd0d7a2a1539" />
    </Box>
    <Caption mdxType="Caption">Don't add unnecessary depth</Caption>
  </Dont>
  <Do mdxType="Do">
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/2941007d-820c-433f-9e5f-b1f3e90c3778" />
    </Box>
    <Caption mdxType="Caption">Use depth when it adds meaning</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/377e1191-191b-4362-9364-24073b98ae3b" />
    </Box>
    <Caption mdxType="Caption">Don't use a 2D perspective if it makes the icon unrecognizable</Caption>
  </Dont>
    </Box>
    <h2>{`Pixel alignment`}</h2>
    <p>{`Align the outer edge of shapes to pixel boundaries when possible.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <Do mdxType="Do">
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/77d28bc7-6247-4bec-add4-1fb774736872" />
    </Box>
    <Caption mdxType="Caption">Align the outer edge of shapes to pixel boundaries</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/13e52ef5-b7bc-46f8-942f-59ac0e4d9dd7" />
    </Box>
    <Caption mdxType="Caption">Don't align the inner edge of shapes to pixel boundaries</Caption>
  </Dont>
    </Box>
    <h2>{`Arrowheads`}</h2>
    <p>{`Use line arrowheads unless there is not enough room.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/ebefdd01-dcce-4be2-b263-bfa164abc16e" />
    </Box>
    <Caption mdxType="Caption">Line arrowhead in sign-out icon</Caption>
  </div>
  <div>
    <Box display="flex" bg="gray.1" justifyContent="center" mdxType="Box">
      <img width="400" alt="" src="https://github.com/primer/design/assets/912236/382f3037-a763-4f31-9cb2-1ea835c7d66c" />
    </Box>
    <Caption mdxType="Caption">Filled arrowhead in link-external icon</Caption>
  </div>
    </Box>
    <h2>{`Contributing`}</h2>
    <h3>{`Creating a pull request`}</h3>
    <p>{`If you work at GitHub, you can use the `}<a parentName="p" {...{
        "href": "https://www.figma.com/community/plugin/825432045044458754/Octicons-Push"
      }}>{`Octicons Push`}</a>{` Figma plugin to start an Octicons pull request from Figma.`}</p>
    <p>{`Here's how it works:`}</p>
    <ol>
      <li parentName="ol">{`Select the icon frames you want to commit. Make sure the frames are either 16x16 or 24x24 and that you've outlined all strokes.`}</li>
      <li parentName="ol">{`Open the Octicons Push plugin.`}</li>
      <li parentName="ol">{`Select the branch you want to commit to. You can choose an existing branch or create a new branch.`}</li>
      <li parentName="ol">{`Press "Commit." The plugin will then export, commit, and push the selected icons to the branch you chose. If you chose to create a new branch, the plugin will give you a link to where you can start a new pull request with your branch.`}</li>
    </ol>
    <p>{`After you create a pull request, a member of the `}<a parentName="p" {...{
        "href": "https://primer.style/about/#team/"
      }}>{`design systems`}</a>{` team will triage and review your contribution.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/4608155/77948730-b1a24600-727a-11ea-9c39-040be9a12963.gif",
        "alt": "demo showing how to create a pull request using the Octicons Push Figma plugin"
      }}></img></p>
    <h3>{`Review questions`}</h3>
    <p>{`Here are a few questions we'll ask when reviewing new octicons. Keep these in mind as you're designing:`}</p>
    <ul>
      <li parentName="ul">{`Where will this icon be used in the context of GitHub UI?`}</li>
      <li parentName="ul">{`Is an icon necessary in that context?`}</li>
      <li parentName="ul">{`Could we use an existing icon?`}</li>
      <li parentName="ul">{`Is the icon trying to represent too many ideas?`}</li>
      <li parentName="ul">{`Does it follow the design guidelines?`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      